<template>
  <b-card>
    <b-alert v-model="showAlert" variant="danger" dismissible>{{
      alertMessage
    }}</b-alert>
    <b-form @submit.prevent>
      <b-form-group
        id="site-access"
        label="Site access"
        label-for="site-access"
        label-class="form-label required"
      >
        <b-dropdown
          id="dropdown-form"
          text="Select"
          ref="siteAccessDropdown"
          block
          menu-class="w-100"
          no-caret
          lazy
          variant="brand-dropdown"
          :toggle-class="{ 'is-invalid': $v.form.userStoreList.$error }"
          :class="{ 'is-invalid': $v.form.userStoreList.$error }"
        >
          <b-dropdown-form @submit.stop.prevent>
            <div>
              <b-form-input
                type="search"
                class="nosubmit w-100"
                placeholder="Search"
                v-model="siteSearchText"
              />
              <b-form-checkbox-group
                class="scrollable mt-4"
                id="sites"
                v-model="form.userStoreList"
                :options="siteOptions"
                name="sites"
              ></b-form-checkbox-group>
            </div>
          </b-dropdown-form>
        </b-dropdown>
        <b-form-invalid-feedback id="dropdown-form-feedback">
          {{ sytemMessages.siteAccessRequired }}
        </b-form-invalid-feedback>
        <div class="row mt-3" v-if="form.userStoreList.length">
          <div class="col-12">
            <div class="tags-group">
              <div
                class="tag tags_title"
                v-for="(_selected, index) in form.userStoreList"
                :key="'site_' + index"
              >
                <div class="d-flex align-items-center">
                  <span class="align-middle">
                    {{ _selected.storeId }} - {{ _selected.storeName }}
                  </span>
                  <i
                    class="icon-close ml-2"
                    @click="deleteSite(index)"
                    role="button"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="firstName"
            label="First Name"
            label-for="firstName"
            label-class="form-label required"
          >
            <b-form-input
              id="firstName"
              v-model="form.firstName"
              placeholder="Enter first name"
              :class="{ 'is-invalid': $v.form.firstName.$error }"
              @keypress="allowOnlyAlphabetic"
            ></b-form-input>
            <b-form-invalid-feedback id="firstName-feedback">
              {{ sytemMessages.firstNameRequired }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="lastName"
            label="Last Name"
            label-for="lastName"
            label-class="form-label required"
          >
            <b-form-input
              id="lastName"
              v-model="form.lastName"
              :class="{ 'is-invalid': $v.form.lastName.$error }"
              @keypress="allowOnlyAlphabetic"
              placeholder="Enter last name"
            ></b-form-input>
            <b-form-invalid-feedback id="lastName-feedback">
              {{ sytemMessages.lastNameRequired }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="emailId"
            label="Email"
            label-for="emailId"
            label-class="form-label required"
          >
            <b-form-input
              id="emailId"
              type="email"
              v-model="form.emailId"
              placeholder="Enter email"
              :class="{
                'is-invalid': $v.form.emailId.$error || duplicateEmail,
              }"
              :disabled="mode == 'edit'"
            ></b-form-input>
            <b-form-invalid-feedback id="emailId-feedback">
              {{ sytemMessages.emailRequired }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="roleType"
            label="Role Type"
            label-for="roleType"
            label-class="form-label required"
          >
            <b-dropdown
              id="roleType"
              :text="roleTypeText"
              ref="dropdown"
              block
              menu-class="w-100 scrollable"
              no-caret
              lazy
              variant="brand-dropdown"
              :toggle-class="{ 'is-invalid': $v.form.userTypeId.$error }"
              :class="{ 'is-invalid': $v.form.userTypeId.$error }"
            >
              <b-dropdown-item
                v-for="(userType, index) in userTypeOptions"
                :key="index"
                @click="form.userTypeId = userType.value"
                :active="form.userTypeId == userType.value"
                >{{ userType.text }}</b-dropdown-item
              >
            </b-dropdown>
            <b-form-invalid-feedback id="roleType-feedback">
              {{ sytemMessages.roleTypeRequired }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex flex-row-reverse">
        <b-button @click="handleSave" variant="brand-primary">Save</b-button>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import config from "../../../../../config";
import { forEach, map } from "lodash";
import { required, minLength, email } from "vuelidate/lib/validators";

const { userManager } = config;

export default {
  name: "Form",
  props: ["mode"],
  data() {
    return {
      userTypeOptions: userManager.userType,
      duplicateEmail: false,
      showAlert: false,
      alertMessage: "",
      siteOptions: [],
      siteSearchText: "",
      form: {
        userId: 0,
        firstName: "",
        lastName: "",
        emailId: "",
        userTypeId: "",
        roleId: 1,
        userStoreList: [],
        loginUserId: 0,
      },
    };
  },
  async mounted() {
    if (this.mode == "edit") {
      if (
        this.$route.params.id &&
        this.$route.params.id != 0 &&
        this.$route.params.id != ""
      ) {
        let id = this.$route.params.id;

        this.generalSetItem({ resource: "isLoading", payload: true });

        await this.getStoreList({ userId: this.loggedUserId });
        this.getById({ id, userId: this.loggedUserId })
          .then((response) => {
            this.generalSetItem({ resource: "isLoading", payload: false });

            if (response.length) {
              let data = response[0];
              const {
                userId,
                emailId,
                firstName,
                lastName,
                sitesAccess,
                userTypeId,
              } = data;
              let userSitesAccess = JSON.parse(sitesAccess);

              let userStoreList =
                Object.keys(userSitesAccess).length > 0
                  ? map(userSitesAccess, (site) => {
                      let _siteName = this.siteOptions.find(
                        (_site) => _site.value.storeId == site.SiteId
                      );

                      return {
                        storeId: site.SiteId,
                        userId: userId,
                        storeName: _siteName.text,
                      };
                    })
                  : [];

              this.form = Object.assign(
                {},
                {
                  userId,
                  emailId,
                  firstName,
                  lastName,
                  userTypeId,
                  userStoreList,
                }
              );
            }
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
            //return back to listing page because error in fetch record
            this.$router.push({ name: "users-list" });
          });
      } else {
        this.$router.push({ name: "users-list" });
      }
    }
  },
  computed: {
    ...mapGetters("userManager", {
      currentUserProfile: "user",
    }),
    ...mapGetters("siteManager", ["storeList"]),
    // siteOptions() {
    //   let options = [];
    //   forEach(this.storeList, (site) => {
    //     let obj = {
    //       text: site.storeName,
    //       value: {
    //         storeId: site.storeId,
    //         userId: this.form.userId,
    //         storeName: site.storeName,
    //       },
    //     };
    //     options.push(obj);
    //   });
    //   return options;
    // },
    roleTypeText() {
      if (this.form.userTypeId == "") return "Select role type";
      let userType = userManager.userType.find(
        (usertype) => usertype.value == this.form.userTypeId
      );
      return userType.text;
    },
  },

  watch: {
    storeList(newVal) {
      if (newVal.length > 0) {
        let options = [];
        let userId =
          this.$route.params.id &&
          this.$route.params.id != 0 &&
          this.$route.params.id != ""
            ? this.$route.params.id
            : 0;
        forEach(this.storeList, (site) => {
          let obj = {
            text: site.storeName,
            value: {
              storeId: site.storeId,
              userId: userId,
              storeName: site.storeName,
            },
          };
          options.push(obj);
        });
        this.siteOptions = options;
      }
    },
    siteSearchText(newVal) {
      if (this.storeList.length > 0) {
        let newOptions = [];
        forEach(this.storeList, (site) => {
          if (
            site.storeName.toLowerCase().includes(newVal.toLowerCase()) ||
            newVal == ""
          ) {
            let obj = {
              text: site.storeName,
              value: {
                storeId: site.storeId,
                userId: this.form.userId,
                storeName: site.storeName,
              },
            };
            newOptions.push(obj);
          }
        });
        this.siteOptions = newOptions;
      }
    },
  },

  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions("userManager", ["manageUser", "getById"]),
    ...mapMutations("userManager", ["setItem"]),
    ...mapActions("siteManager", ["getStoreList"]),
    async handleSave() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) return;

      this.form.loginUserId = this.loggedUserId;

      if (this.mode == "edit")
        this.form.emailId = this.currentUserProfile.emailId;

      this.generalSetItem({ resource: "isLoading", payload: true });

      let _userStoreList = this.form.userStoreList.map((_site) => {
        return { storeId: _site.storeId, userId: _site.userId };
      });

      this.form.userStoreList = _userStoreList;

      this.manageUser({ form: this.form, userId: this.loggedUserId })
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });

          if (response.status == 1) {
            this.$router.push({ name: "users-list" });
          } else {
            this.showAlert = true;
            this.duplicateEmail = false;
            if (response.type == "DuplicateEmail") {
              this.duplicateEmail = true;
              this.alertMessage = response.error;
            } else {
              this.alertMessage = response.error;
            }
          }
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    deleteSite(index) {
      this.form.userStoreList.splice(index, 1);
    },
  },
  created() {
    this.generalSetItem({ resource: "isLoading", payload: true });
    this.getStoreList({ userId: this.loggedUserId })
      .then(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      })
      .catch(() =>
        this.generalSetItem({ resource: "isLoading", payload: false })
      );
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      emailId: { required, email },
      userStoreList: { required, minLength: minLength(1) },
      userTypeId: { required },
    },
  },
  destroyed() {
    this.form = {
      userId: 0,
      firstName: "",
      lastName: "",
      emailId: "",
      userTypeId: "",
      roleId: 1,
      userStoreList: [],
      loginUserId: 0,
    };
  },
};
</script>
